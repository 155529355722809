
const saveAuth = (data)=> {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem('user', JSON.stringify(data));
}

const getToken = () => {
    if (typeof window === 'undefined') return;
    const user = window.localStorage.getItem('user');
    if(!user) return '';
    console.log('getToken = ', JSON.parse(user))
    return JSON.parse(user).jwt;
}

const getDomainAgence = () => {
    if (typeof window === 'undefined') return;
    const domain = window.localStorage.getItem('domain');
    if(!domain) return '';
    console.log('domain = ', domain)
    return domain;
}
const saveDomainAgence = (domain)=> {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem('domain', domain);
}

const getUser = () => {
    if (typeof window === 'undefined') return;
    const user = window.localStorage.getItem('user');
    if(!user) return '';
    return JSON.parse(user).user;
}

const clearAuth = () => {
    if (typeof window === 'undefined') return;
    window.localStorage.removeItem('user');
}

module.exports = {
    getToken,
    getUser,
    saveAuth,
    clearAuth,
    getDomainAgence,
    saveDomainAgence
}