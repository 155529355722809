import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import "./layout.css";
import { Grommet, Box, ThemeContext, ResponsiveContext } from "grommet";
import { grommet } from "grommet/themes";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Grommet
      theme={grommet}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ThemeContext.Extend
        value={{
          global: {
            colors: {
              brand: {
                dark: "#72ACFF",
                light: "#E92C70",
              },
              background: {
                dark: "#111111",
                light: "#FFFFFF",
              },
              "background-back": {
                dark: "#111111",
                light: "#EEEEEE",
              },
              "background-front": {
                dark: "#222222",
                light: "#FFFFFF",
              },
              "background-contrast": {
                dark: "#FFFFFF11",
                light: "#11111111",
              },
              text: {
                dark: "#EEEEEE",
                light: "#333333",
              },
              "text-strong": {
                dark: "#FFFFFF",
                light: "#000000",
              },
              "text-weak": {
                dark: "#CCCCCC",
                light: "#444444",
              },
              "text-xweak": {
                dark: "#999999",
                light: "#666666",
              },
              border: {
                dark: "#444444",
                light: "#CCCCCC",
              },
              control: "brand",
              "active-background": "background-contrast",
              "active-text": "text-strong",
              "selected-background": "brand",
              "selected-text": "text-strong",
              "status-critical": "#FF4040",
              "status-warning": "#FFAA15",
              "status-ok": "#00C781",
              "status-unknown": "#CCCCCC",
              "status-disabled": "#CCCCCC",
              "graph-0": "brand",
              "graph-1": "status-warning",
            },
            font: {
              family: "Montserrat",
              size: "14px",
              // height: "28px",
              // maxWidth: "588px",
            },
            active: {
              background: "active-background",
              color: "active-text",
            },
            hover: {
              background: "active-background",
              color: "active-text",
            },
            selected: {
              background: "selected-background",
              color: "selected-text",
            },
            borderSize: {
              xsmall: "1px",
              small: "2px",
              medium: "5px",
              large: "14px",
              xlarge: "28px",
            },
            breakpoints: {
              small: {
                value: 896,
                borderSize: {
                  xsmall: "1px",
                  small: "2px",
                  medium: "5px",
                  large: "7px",
                  xlarge: "14px",
                },
                edgeSize: {
                  none: "0px",
                  hair: "1px",
                  xxsmall: "2px",
                  xsmall: "4px",
                  small: "7px",
                  medium: "14px",
                  large: "28px",
                  xlarge: "56px",
                },
                size: {
                  xxsmall: "28px",
                  xsmall: "56px",
                  small: "112px",
                  medium: "224px",
                  large: "448px",
                  xlarge: "896px",
                  full: "100%",
                },
              },
              medium: {
                value: 1792,
              },
              large: {},
            },
            edgeSize: {
              none: "0px",
              hair: "1px",
              xxsmall: "4px",
              xsmall: "7px",
              small: "14px",
              medium: "28px",
              large: "56px",
              xlarge: "112px",
              responsiveBreakpoint: "small",
            },
            input: {
              padding: "13px",
              weight: 600,
              fontSize: "14px",
            },
            spacing: "28px",
            size: {
              xxsmall: "56px",
              xsmall: "112px",
              small: "224px",
              medium: "448px",
              large: "896px",
              xlarge: "1344px",
              xxlarge: "1792px",
              full: "100%",
            },
          },
          text: {
            medium: {
              size: "14px",
            }
          },
          rangeInput: {
            track: {
              height: "10px",
              lower: {
                color: "#59A4F4",
                opacity: 0.7,
              },
              upper: {
                color: "dark-4",
                opacity: 0.3,
              },
            },
            thumb: {
              color: "#fff",
            },
          },
          tab: {
            active: {
              color: "#707070",
              weight: "bold",
            },
            border: {
              side: "bottom",
              size: "small",
              color: {
                dark: "transparent",
                light: "transparent",
              },
              active: {
                color: {
                  dark: "#707070",
                  light: "#707070",
                },
              },
              disabled: {},
              hover: {
                color: {
                  dark: "brand",
                  light: "brand",
                },
              },
            },
            color: "#707070",
            hover: {
              color: {
                dark: "brand",
                light: "brand",
              },
            },
            margin: {
              vertical: "xxsmall",
              horizontal: "small",
            },
            pad: {
              bottom: "xsmall",
            },
          },
        }}
      >
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box full="vertical" style={{ height: "100%", minHeight: "100vh" }}>
              {children}
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </ThemeContext.Extend>
    </Grommet>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
